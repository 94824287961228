import "./footer.css";
import ScrollingDivs from "./ScrollingDivs";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Header = () => {
  return (
    <div id="page-wrap" className="">
      <ScrollingDivs/>
    </div>
  );
};

export default Header;
