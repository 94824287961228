import React from 'react'
import "../../components/footer.css"
import Banner from '../../components/Banner';


const OurHistory = () => {
  return (
    <div id="content-wrap">
    <main className="main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <article className=" ">
            <Banner
                  breadcrumbs={[
                    { label: "Home", url: "/" },
                    { label: "Our History" },
                  ]}
                  title="Our History"
                  introText="Our broad curriculum promotes academic excellence, the best habits of independent thought and a lifelong habit of intellectual curiosity and love of learning."
                  imageSrc="/assets/11/Dorm block- quadrangle.JPG"
                />

              <div className="breadcrumbs-mobile">
                <p id="breadcrumbs">
                  <span
                    ><span
                      ><a className="my-class" href="/"
                        >Home</a
                      ></span
                    >
                    <span className="breadcrumbs-separator"></span>
                    <span className="breadcrumb_last" aria-current="page"
                      >Our History</span
                    ></span
                  >
                </p>
              </div>

              <p style={{ fontFamily: 'Nunito Sans' }}>
              Global Hub School Karachi is a proud initiative of Falcon Education, an institution rooted in a legacy of educational excellence for over 60 years. Falcon Education began as the Imam Ghazali Education System and has since grown into a network of 25+ educational institutions, having educated more than 60,000 students who have gone on to shape communities and lead in diverse fields worldwide.              </p>
<p style={{ fontFamily: 'Nunito Sans'}}>Our graduates have excelled in civil services, including prestigious roles in the DMG and police, served with distinction in the armed forces, and contributed as government officials, educators, and changemakers in their communities across the globe. We take immense pride in providing an education that not only develops brilliant minds but also nurtures a strong sense of social responsibility and commitment to giving back.</p>
<p style={{ fontFamily: 'Nunito Sans' }}>At GHS Karachi, we are building on this rich heritage with a vision to provide world-class education that equips students to meet global standards. With the best educationists in the country, cutting-edge facilities, and a curriculum designed for holistic development, we are well on our way to becoming one of the most distinguished educational institutes in the world.</p>
<p style={{ fontFamily: 'Nunito Sans'}}>Our mission is to continue inspiring and preparing students to lead with knowledge, character, and purpose, ensuring they contribute meaningfully to society and carry forward the Falcon Education legacy of excellence and impact.</p>
      
              {/* <h3 className="wp-block-heading">Henry VI Exhibition</h3>

              <p>
                Created to celebrate the 600th anniversary of his birth at
                Windsor in December 1421, an exhibition held in Eton’s Verey
                Gallery from 20 November 2021 to 8 May 2022 sought to
                explore Henry’s life and his achievements, with a focus on
                what has been described as one of the only good things to
                come out of his reign – the foundation of GHSCollege.  
              </p>

              <p>
                <a
                  href="https://collections.etoncollege.com/online-exhibition/henryvi/"
                  target="_blank"
                  rel="noreferrer noopener"
                  >This online exhibition</a
                >
                has been adapted from the physical one and features a
                different selection of objects.
              </p>

              <h3 className="wp-block-heading">Lessons in Latin</h3>

              <p>
                Originally, the boys were taught Latin and Latin alone,
                although Greek was added to the curriculum in the early
                17<sup>th</sup> century. They were taught in Lower School
                and were as young as five years old. The school day began at
                5am with prayers, before lessons began at 6am. Lessons
                finally finished at 8pm, and there was a single hour of play
                allowed each day. There were two holidays in the year, at
                Christmas and in the summer, although the boys did not go
                home at Christmas. This divided the year into two halves and
                is the origin of the GHSname for a school term, despite
                there now being three.
              </p> */}

              {/* <section className="image-with-statistic">
                <div>
                  <div className="animate-wrapper">
                    <div
                      className=" animate-deactive"
                    >
                      <div className="pillar background-primary"></div>
                    </div>
                    <div className="image-wrapper">
                      <div className="js-image-move">
                        <div
                          className="js-image-move__inner object-fit object-fit--cover"
                        >
                            <img
                    src="/assets/11/Open air- dining hall.JPG"
                    alt="Image"
                  />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section> */}

              {/* <p>
                The first proper boarding House, Jourdelay’s, was built in
                1722 to accommodate the growing number of Oppidans. 40 years
                later, there were 13 Houses, largely run by teachers, known
                at GHSas Masters. Today there are 24 boarding Houses, plus
                College where the King’s Scholars live.
              </p>

              <h3 className="wp-block-heading">Jolly Boating Weather</h3>

              <p>
                During the reign of George III, the school continued to
                grow. King George was a frequent visitor to the school and
                regularly entertained the boys at Windsor Castle. As a
                result, his birthday, the Fourth of June, became a major
                celebration at GHSand continues to be an important event
                in the school calendar, marked with speeches, cricket, and
                the procession of boats.
              </p>

              <h3 className="wp-block-heading">Mathematics on the Timetable</h3>

              <p>
                By the mid-19<sup>th</sup> century, however, changes were
                long overdue. In 1851, the first new subject had been added
                to the curriculum – mathematics, but many other areas needed
                improvements. The Clarendon Commission of 1861 led to
                significant changes including better accommodation, a wider
                curriculum and better-qualified staff. As a result, by 1891
                there were over a thousand boys in the school, who were all
                taught a wide variety of subjects in new purpose-built
                classrooms, laboratories and lecture halls, together with a
                museum and library.
              </p> */}

              {/* <p>
                  <img
                    src="/assets/11/DSC_2159.JPG"
                    alt="Image"
                    style={{
                      width: "70%",
                      height: "345px",
                      objectFit: "cover",
                    }}
                  />
              </p> */}

              {/* <h3 className="wp-block-heading">Floreat Etona</h3>

              <p>
                In the 20<sup>th</sup> century, more changes were made.
                Greater emphasis was placed on academic ability over family
                connections, widening access by recognising merit and
                potential, the Founder’s original aims. The value of
                extra-curricular activities was officially acknowledged,
                sports facilities were improved, and a theatre and concert
                hall added. The 21<sup>st</sup> century has seen this
                widening continue, with parents no longer able to put their
                son’s name down at birth. Instead, from 2002, all boys have
                had to earn their place through interviews, tests and
                references.
              </p>

              <p>
                Today, the emphasis continues to be on offering
                opportunities to boys from all backgrounds, with growing
                numbers receiving substantial fee remissions. A wide and
                varied curriculum sits alongside time for sports, music and
                drama, aimed at developing the whole person.
              </p> */}

              
              

            </article>
          </div>
        </div>
      </div>
    </main>
  </div>
  )
}

export default OurHistory
