import React from 'react';
import Header from '../components/Header';

export const HomePage = () => {
  return (
    <div>
    <Header/>
    </div>
  );
};
